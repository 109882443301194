import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights
} from './FooterElements'
import {
    FaDiscord,
    FaReddit,
    FaTelegram,
    FaTwitter} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to="/" onClick={toggleHome}>
                                One Grand
                            </SocialLogo>
                            <WebsiteRights>{new Date().getFullYear()} All rights reserved.
                            </WebsiteRights>
                            <SocialIcons>
                                <SocialIconLink
                                    href="https://x.com/onegrandxyz"
                                    target="_blank"
                                    aria-label="Twitter">
                                    <FaTwitter />
                                </SocialIconLink>
                                <SocialIconLink
                                    href="https://discord.gg/2MmdsyWsX3"
                                    target="_blank"
                                    aria-label="Discord">
                                    <FaDiscord />
                                </SocialIconLink>
                                <SocialIconLink
                                    href="https://www.reddit.com/r/onegrand/"
                                    target="_blank"
                                    aria-label="Reddit">
                                    <FaReddit />
                                </SocialIconLink>
                                <SocialIconLink
                                    href="https://t.me/onegrandxyz"
                                    target="_blank"
                                    aria-label="Telegram">
                                    <FaTelegram />
                                </SocialIconLink>
                            </SocialIcons>                            
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer
