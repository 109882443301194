import React from "react";
import { Bar, Denominator, Fraction, HeaderCell, Link, Numerator, Table, TableCell, TableRow } from "./FaqElements";

export const homeObj4 = {
    id: 'faq',
    lightBg: true,
    lightText: false,
    topLine: 'FAQ',
    headline: 'Frequently Asked Questions',
    dark: false,
    primary: false,
    darkText: true,
    questions: [
        {
            question: "What is the token address?",
            answer: (
                <>
                    <Link
                        href="https://solscan.io/token/9SNVDQDmiFWWeyvrKHZYWHH8QMng9okkeMiictphZiHb"
                        target="_blank"
                    >9SNVDQDmiFWWeyvrKHZYWHH8QMng9okkeMiictphZiHb</Link>
                </>
            )
        },
        {
            question: "Why does RugCheck say danger?",
            answer: (
                <>
                    If you look at the <a 
                        href="https://rugcheck.xyz/tokens/9SNVDQDmiFWWeyvrKHZYWHH8QMng9okkeMiictphZiHb" 
                        target="_blank">RugCheck</a>, it shows danger because 
                    the <a
                        href="https://solana.fm/address/5NXW9f6VGBGQTzyQ2iXoS1e4x2v1msisXrDKmoQCQMri"
                        target="_blank">Meteora DLMM bootstrapping pool</a> is 
                    showing up as a token holder instead of liquidity.  It will 
                    continue to show as the majority holder until all tokens 
                    are sold.  Because of this, it shows high single holder 
                    ownership and no liquidity.  We have reached out to FluxBeam
                    to see if they can update RugCheck to recognize 
                    bootstrapping pools as liquidity, and also recognize the 
                    time locked liquidity.
                </>
            )
        },
        {
            question: "What is a Stable Floor Token?",
            answer: (
                <>
                    A stable floor token has a known minimum price that can 
                    never be broken.  How is that even possible?  Read on...
                </>
            )
        },
        {
            question: "How will you ensure that One Grand never goes below 1,000 USDC?",
            answer: (
                <>
                    All of 1,000 tokens will be put into a Meteora DLMM 
                    bootstrapping pool, at a price of 1,000 USDC.  This pool 
                    will have a fee of 0.01%, because we are not looking to make 
                    money on fees or even with the capital raise.  All 1,000 
                    tokens will be placed in a single bin, ensuring everyone 
                    pays the same initial price of 1,000 USDC per token.  The 
                    liquidity in this launch pool will be time locked for 1,000 
                    years.  Everyone on the launch team and even their 
                    grandchildren will die of old age before it ever unlocks.
                <br/>
                <br/>
                    Anyone who bought initially that wants to sell it, is 
                    guaranteed that they will be able to at least get back 
                    their initial investment, less the 0.01% fee that will be 
                    paid to sell it back to the launch pool.  Our hope is that 
                    initial investors won't ever need to sell it back to the 
                    launch pool, because after we sell the full supply, the 
                    token will moon.  Initial One Grand investors can HODL
                    onto it forever in some fashion if they wish, without any 
                    fear of ever losing their initial investment.
                </>
            )
        },
        {
            question: "What is the total supply?",
            answer: (
                <>
                    Only 1,000 tokens, giving it an initial market cap of 
                    1,000,000 USDC.  We need to sell all 1,000 tokens for the 
                    price to ever exceed 1,000.  We believe that the promise of 
                    stable floor token will go viral, and we will easily sell 
                    the full supply of 1,000. The numbers are simple and easy 
                    to remember.  Based on the current price you can easily 
                    calculate the market cap, just add three zeros.
                    <Table>
                        <TableRow><HeaderCell>Price</HeaderCell><HeaderCell>Market Cap</HeaderCell></TableRow>
                        <TableRow><TableCell>1,000</TableCell><TableCell>1,000,000</TableCell></TableRow>
                        <TableRow><TableCell>10,000</TableCell><TableCell>10,000,000</TableCell></TableRow>
                        <TableRow><TableCell>100,000</TableCell><TableCell>100,000,000</TableCell></TableRow>
                        <TableRow><TableCell>1,000,000</TableCell><TableCell>1,000,000,000</TableCell></TableRow>
                    </Table>
                </>
            )
        },
        {
            question: "What happens if you don't sell the full supply of 1,000 tokens?",
            answer: (
                <>
                    The price will oscillate between 1,000.10 and 999.9, which 
                    is the 1,000 price in the launch pool less fees.  We expect 
                    there there could be some initial oscillation in price until 
                    it sells out.
                </>
            )
        },
        {
            question: "Why did you create One Grand?",
            answer: (
                <>
                All the meme coins out there only benefit those that are using 
                snipe bots to buy early before the price pumps.  It is unfair to 
                other participants in the market that aren't able to get in on 
                the ground floor.  We wanted to create a meme coin with a fair 
                launch for everyone, and giving traders and investors the 
                opportunity to get in at the same price.
                <br/>
                <br/>
                We also wanted our meme coin to be memorable, so we gave it a 
                floor price of 1,000 USDC, a supply of 1,000, and a name to 
                match.  We wanted to do something different and interesting to 
                test out new technology made possible only by Meteora.
                </>
            )
        },
        {
            question: "Is it really 100% risk-free?",
            answer: (
                <>
                    It is approximately 99.98% risk-free, but that's not as easy 
                    to explain or as catchy as just rounding it and saying 
                    it's "100% risk-free".  You will pay a 0.01% fee to buy it, 
                    and if the price does bottom out to 1,000 USDC or we never 
                    sell all 1,000 tokens, when you sell it back to the locked 
                    liquidity pool, you will pay a fee of 0.01% once again.  So 
                    the risk formula is:
                    <br/>
                    <span style={{padding: "25px"}}>
                        &nbsp;
                        = 1 - <Fraction>
                            <Numerator>
                                Buy Cost - Sell Revenue
                            </Numerator>
                            <Bar>/</Bar>
                            <Denominator>
                                Buy Cost
                            </Denominator>
                        </Fraction>
                    </span>
                    <br/>
                    <span style={{padding: "25px"}}>
                        &nbsp;
                        = 1 - <Fraction>
                            <Numerator>
                                (100% + 0.01%) - (100% - 0.01%)
                            </Numerator>
                            <Bar>/</Bar>
                            <Denominator>
                                100% + 0.01%
                            </Denominator>
                        </Fraction>
                    </span>
                    <br/>
                    <span style={{padding: "25px"}}>
                        &nbsp;
                        ≈ 99.98%
                    </span>
                </>
            )
        },
        {
            question: "How is One Grand different from all the other meme tokens out there?",
            answer: (
                <>
                There are a lot of shennanigans that take place with meme coin 
                launches.  Teams set aside an allocation for themselves, when 
                the price pumps, they dump it.  When they launch, they'll snipe 
                it themselves and eventually dump it.  They will launch with 
                very little initial liquidity, and so the tokens trade for 
                fractions of a penny.  They all launch in standard AMMs, which 
                result in siginificant price movement in early trading, and 
                only those that get in within a few seconds of the launch can 
                win big.
                <br/>
                <br/>
                One Grand is different, because we will use Meteora DLMM to 
                ensure there is zero price impact at launch.  This ensures 
                everyone gets the same initial price with no price impact as 
                the initial supply is sold.  With One Grand there are no meme 
                coin shennanigans.  Once the full supply of 1,000 tokens is 
                sold, the price is free to move up from there.  🚀🌕
                <br/>
                <br/>
                We think that One Grand will be an interesting experiment to 
                see what can happen to token price and volume, when there is a 
                well-known stable floor that can never be broken.
                </>
            )
        },
        {
            question: "Why don't other meme coins launch this way?",
            answer: (
                <>
                    Most meme token launchers just follow whatever the current 
                    meta is, including the launch process.  Their mindset is 
                    "if it ain't broke, don't fix it." We believe that it is 
                    broke, and it's time to fix it.
                </>
            )
        },
        {
            question: "What's in it for you?",
            answer: (
                <>
                    We will be buying the token along with all of you, and will 
                    profit from captial appreciation along with you when it 
                    moons!  We will also LP it on Meteora outside of the launch 
                    pool, and print fees with it too.
                </>
            )
        },
        {
            question: "Who are you guys?",
            answer: (
                <>
                    We are a small startup, &nbsp;
                    <Link
                        href="https://blackbit.dev"
                        target="_blank"
                    >Black Bit Technologies</Link>.  We are seven members strong, 
                    but we all have day jobs.  We mostly work on <Link 
                        href="https://twitter.com/GeekLad"
                        target="_blank"
                    >@GeekLad's</Link> harebrained ideas in our spare time on 
                    nights and weekends, and One Grand is no 
                    exception.
                </>
            )
        },
        {
            question: "What are your hopes and dreams for One Grand?",
            answer: (
                <>
                    GeekLad has become a huge fan of Meteora and member of the 
                    community.  One Grand is his brain child, and we want One 
                    Grand to become a go-to token for Meteora liquidity 
                    providers.  We want to see speculation, volatility, and 
                    price discovery on the token.  We want to see the LP Army 
                    buy it, provide liquidity for it on Meteora, and print tons 
                    of fees with it.
                    <br />
                    <br />                    
                    We want to see the token price to hit tens of thousands, 
                    possibly hundreds of thousands, because it would be fun to 
                    have created a token with a price higher than Bitcoin, 
                    even if it still has a relatively small market cap.  We 
                    want to create something different, interesting, and reward 
                    the LP Army with something special.
                </>
            )
        },
        {
            question: "Is GeekLad part of the Meteora team?",
            answer: (
                <>
                    Nope!  GeekLad is not part of the Meteora team, and this 
                    token has no direct affiliation with Meteora.  GeekLad does 
                    have aspirations to spend a lot more time working on 
                    Meteora related tools and content.  If the One Grand launch 
                    goes really well, it could accelerate that process.
                </>
            )
        },
        {
            question: "When are you guys going to launch another token?",
            answer: (
                <>
                    After One Grand is a huge success and hits a market cap in
                    the hundreds of millions.  Actually, even if One Grand is a 
                    total bust, we're going to learn from our mistakes and 
                    launch another token anyway.  All that is to say, we don't 
                    know when, only that it will be at some point in the future.
                </>
            )
        },
        {
            question: "What's up with the weird price action on April 24?",
            answer: (
                <>
                    The price action you will see on &nbsp;
                    <Link
                        href="https://birdeye.so/token/9SNVDQDmiFWWeyvrKHZYWHH8QMng9okkeMiictphZiHb?chain=solana"
                        target="_blank"
                    >price charts such as BirdEye</Link> on April 24, 2024 was 
                    due to some early experimentation we were doing in a higher 
                    fee DLMM we created.
                    <br/>
                    <br/>
                    Initially, we thought it was possible to lock the liquidity 
                    in standard Meteora DLMM Pools, but we were mistaken.  This 
                    is only possible in Meteora Dynamic Pools.  We were also 
                    considering earning fees from the launch, and we were
                    trying to set the pool price so that anyone selling tokens 
                    to the launch pool would get exactly 1,000 USDC back.
                    <br/>
                    <br/>
                    We didn't get the pricing right, and it didn't matter anyway
                    since we couldn't lock the liquidity.  Launching in a 
                    low-fee pool ultimately aligns better with the vision for 
                    One Grand anyway.  We removed the tokens from that DLMM, 
                    which is why you'll see the chart flatline between then 
                    and the launch day.
                </>
            )
        }
    ]
}
