import React, { useState } from 'react'
import Hero from '../Components/Hero/Index'
import InfoSection from '../Components/Info/Index'
import { homeObj1 } from '../Components/Info/Data'
import Footer from '../Components/Footer/Index'
import Navbar from '../Components/Navbar/Index'
import FaqSection from '../Components/Faq/Index'
import { homeObj4 } from '../Components/Faq/Data'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Navbar />
            <Hero />
            <InfoSection  {...homeObj1} />
            <FaqSection {...homeObj4} />
            <Footer />
        </>
    )
}

export default Home
