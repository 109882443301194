import React from "react";

export const buttonURL = "https://app.meteora.ag/dlmm/5NXW9f6VGBGQTzyQ2iXoS1e4x2v1msisXrDKmoQCQMri";
export const homeObj1 = {
    id: 'tokenomics',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    dark: true,
    primary: true,
    darkText: false,
    imgStart: false,
    topLine: 'Tokenomics',
    headline: '100% risk-free. No loss. Fair launch.',
    description: (
        <>
            No airdrop, no team allocation, no reserve, no VCs, no treasury, 
            no dillution, no exchanges, no bullshit.  Fair and open launch.  
            Everyone pays the same initial price of 1,000 USDC.
            <br/>
            <br/>
            100% risk-free for initial investors that get in at the ground
            floor.  No guarantees after that, so get in quick before it 
            pumps!
        </>
    ),
    buttonLabel: 'Buy on Meteora',
    img:'/one-grand-launch.svg',
    alt: 'Rocket with the One Grand logo launching',
}