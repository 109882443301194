import React, { useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    } from './NavElements'
import { Img } from '../Info/InfoElements';

const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }


    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome}>
                        <Img 
                            src="/logo.webp" 
                            style={{maxHeight: "1.5rem", width: "auto", margin: 0, "padding-right": "1px"}}
                            alt="One Grand logo"
                        />NE GRAND
                    </NavLogo>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar
