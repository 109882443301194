import styled from 'styled-components'

export const FaqContainer = styled.div`
color: #fff;
background: ${({ lightBg }) => (lightBg ? '#f4f4ee' : '#010606')};
padding-top: 0px;

@media screen and (max-width:768px) {
    padding: 50px 15px;
}
`

export const FaqWrapper = styled.div`
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding-top: 30px;
justify-content: center;
`

export const FaqContent = styled.div`
${'' /* padding: 10px; */}
`

export const TopLine = styled.p`
color: #01bf71;
font-size: 16px;
line-height:16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`
export const Heading = styled.h1`
color: #000;
margin-bottom: 24px;
font-size: 48px;
line-height:1.1;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};


@media screen and (max-width:480px) {
    font-size: 32px;
}
`

export const Question = styled.h2`
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const Answer = styled.p`
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const Link = styled.a`
color: rgb(1, 191, 113);
`

export const Fraction = styled.div`
  display: inline-block;
  vertical-align: middle; 
  text-align: center;
  padding-top: 10px;
`

export const Numerator = styled.span`
`

export const Bar = styled.span`
  display: none;
`

export const Denominator = styled.span`
  display: block;
  border-top: thin solid black;
  padding-top: 0.15em;
`

export const Table = styled.table`
  margin: 15px;
`

export const TableRow = styled.tr`
`

export const HeaderCell = styled.th`
  text-align: right;
  padding-right: 5px;
`

export const TableCell = styled.td`
text-align: right;
padding-right: 5px;
`