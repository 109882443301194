import React from 'react'
import {
    FaqContainer,
    Heading,
    TopLine,
    Question,
    Answer,
    FaqWrapper
} from '../Faq/FaqElements'

const FaqSection = ({
    id,
    lightBg,
    topLine,
    headline,
    lightText,
    darkText,
    questions
}) => {

    return (
        <>
            <FaqContainer lightBg={lightBg} id={id}>
                <FaqWrapper>
                {/* <InfoWrapper>
                    <InfoRow>
                        <Column>
                            <TextWrapper> */}
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                {
                                    questions.map(q => (
                                        <>
                                            <Question darkText={darkText}>{q.question}</Question>
                                            <Answer darkText={darkText}>{q.answer}</Answer>
                                            <br/>
                                        </>
                                    ))
                                }
                            {/* </TextWrapper>
                        </Column>
                    </InfoRow>
                </InfoWrapper> */}
                </FaqWrapper>
            </FaqContainer>
        </>
    )
}

export default FaqSection
