import React, { useState } from 'react'
import {
    HeroContainer,
    ArrowForward,
    HeroContent,
    HeroP,
    HeroBtnWrapper,
    ArrowRight,
    ImgWrap,
    Img
} from '../Hero/HeroElements'
import { Button } from '../../Utilities/ButtonElements'
import { buttonURL } from '../Info/Data'

const Hero = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
        if(hover) {
            window.status = buttonURL;
        }
    }

    return (
        <>
            <HeroContainer>
                <HeroContent>
                    <ImgWrap>
                        <Img
                            fetchpriority="high"  
                            src="/one-grand-animated-square.webp"
                            alt="Spinning One Grand coin"
                            width="300px" 
                            height="300px"
                        ></Img>
                    </ImgWrap>
                    <HeroP>
                        The world's first Stable Floor Token (SFT)
                    </HeroP>
                        <HeroBtnWrapper>
                        <a href={buttonURL} style={{textDecoration: "none"}}>
                            <Button
                                    onMouseEnter={onHover}
                                    onMouseLeave={onHover}
                                    primary="true"
                                    dark="true"
                                    onClick={function() {
                                        document.location.href=buttonURL
                                    }}                            
                                >
                                Buy on Meteora {hover ? <ArrowForward /> : <ArrowRight />}
                            </Button>
                        </a>
                        </HeroBtnWrapper>
                </HeroContent>
            </HeroContainer>
        </>
    )
}

export default Hero
